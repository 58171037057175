import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center text-sm text-grey text-opacity-30"
}
const _hoisted_2 = {
  key: 1,
  class: "flex justify-center text-sm text-grey text-opacity-30"
}
const _hoisted_3 = {
  key: 2,
  "data-cy": "notificationList",
  class: "mt-1"
}
const _hoisted_4 = { class: "flex items-start justify-between" }
const _hoisted_5 = { class: "text-lg" }
const _hoisted_6 = { class: "text-sm" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex flex-col items-center" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "text-sm mt-1" }
const _hoisted_12 = { class: "absolute bottom-1 right-4 text-xs" }
const _hoisted_13 = {
  key: 3,
  class: "text-center text-xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirm_modal = _resolveComponent("confirm-modal")!
  const _component_status_modal = _resolveComponent("status-modal")!
  const _component_notification_page_skeleton = _resolveComponent("notification-page-skeleton")!
  const _component_standart_template = _resolveComponent("standart-template")!

  return (_openBlock(), _createBlock(_component_standart_template, null, {
    default: _withCtx(() => [
      (_ctx.appVersion)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.appVersion), 1))
        : _createCommentVNode("", true),
      (_ctx.userData && _ctx.userData.phone_number)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Выплаты аккаунта: " + _toDisplayString(_ctx.userData.phone_number), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoadData && _ctx.notifications.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "relative mb-3 rounded-md bg-greyLight p-4"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(item.card_name), 1),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", null, "Списано: " + _toDisplayString(item.amount), 1),
                      (item.commission_withheld)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Отправлено: " + _toDisplayString(_ctx.mathCommission(item.amount, item.commission_withheld)), 1))
                        : _createCommentVNode("", true),
                      (item.commission_withheld)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Комиссия: " + _toDisplayString(item.commission_withheld), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.validateTransactionStatus(item.status, 'color'), "flex w-full justify-center rounded-md py-1 px-2 text-sm text-white"])
                    }, _toDisplayString(_ctx.validateTransactionStatus(item.status, "text")), 3),
                    (item.date_time && _ctx.isActiveContactParent(item.date_time))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          onClick: ($event: any) => (_ctx.validateNotComingMoney(item)),
                          class: "cursor-pointer mt-3 flex w-full items-center justify-center rounded-full bg-background py-1 px-2 text-sm text-blue"
                        }, " Не получил ", 8, _hoisted_10))
                      : _createCommentVNode("", true),
                    (item.date_time && !_ctx.isActiveContactParent(item.date_time))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (this.disabledDialogModal = true)),
                          class: "cursor-pointer mt-3 flex w-full items-center justify-center rounded-full bg-background py-1 px-2 text-sm text-blue"
                        }, " Не получил "))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, _toDisplayString(item.card_number), 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.formatDate(item.date_time)), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.isLoadData && _ctx.count <= 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, " У вас еще нет уведомлений, но они обязательно появятся "))
        : _createCommentVNode("", true),
      _createVNode(_component_confirm_modal, {
        description: [
        'Обращение в поддержку доступно через 15 минут после перевода. Проведение платежа может затянуться.',
      ],
        state: this.disabledDialogModal,
        "confirm-button-text": "Подождать",
        onConfirm: _cache[1] || (_cache[1] = ($event: any) => (this.disabledDialogModal = false))
      }, null, 8, ["description", "state"]),
      _createVNode(_component_confirm_modal, {
        state: _ctx.helpDialogModal,
        "confirm-button-text": 
        this.currentNotification?.status_payout == 'SCORE_NOT_ENOUGH_MONEY'
          ? 'Сообщить в «Парк»'
          : 'Не получил'
      ,
        "decline-button-text": 
        this.currentNotification?.status_payout == 'SCORE_NOT_ENOUGH_MONEY'
          ? 'Подождать'
          : 'Проверить'
      ,
        description: 
        this.currentNotification?.status_payout == 'SCORE_NOT_ENOUGH_MONEY'
          ? [
              `Перевод в ${_ctx.formatDate(
                this.currentNotification.date_time,
              )} не прошёл так как на счёте партнёра («Парка») было недостаточно средств. 
              Дождитесь пополнения. 
              Попробуйте позже.`,
            ]
          : [
              `Проверьте списание с баланса и нажмите «Не получил». Поддержка приложения работает с 10 до 22 по МСК.`,
            ]
      ,
        onDecline: _cache[2] || (_cache[2] = ($event: any) => (
        this.currentNotification?.status_payout == 'SCORE_NOT_ENOUGH_MONEY'
          ? _ctx.closeHelpDialogModal()
          : _ctx.waitingForPayment()
      )),
        onConfirm: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sendWhatsAppMessage()))
      }, null, 8, ["state", "confirm-button-text", "decline-button-text", "description"]),
      _createVNode(_component_status_modal, {
        state: _ctx.statusModalDialog,
        "button-text": "Понял",
        description: _ctx.statusModalText,
        type: "warning",
        onConfirm: _cache[4] || (_cache[4] = ($event: any) => (_ctx.statusModalDialog = false))
      }, null, 8, ["state", "description"]),
      (!_ctx.isLoadData)
        ? (_openBlock(), _createBlock(_component_notification_page_skeleton, { key: 4 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}