
import { Options, Vue } from "vue-class-component";
import InputComponent from "@/components/Common/InputComponent.vue";
import { Prop, Watch } from "vue-property-decorator";

@Options({
  components: { InputComponent },
})
export default class ChooseSBPBank extends Vue {
  @Prop() public modelValue!: boolean;

  @Watch("modelValue")
  watchModalValue(new_value: boolean) {
    if (new_value) {
      return document.body.classList.add("overflow-hidden");
    }
    if (!new_value) {
      return document.body.classList.remove("overflow-hidden");
    }
  }
}
