
import { Options, Vue } from "vue-class-component";
import ApiEnter from "@/api/ApiEnter";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import UtilStorage from "@/util/Common/UtilStorage";
import LoginStep from "@/components/Enter/LoginStep.vue";
import { ELoginStepYandex } from "@/struct/ELogin/ELoginStep";
import { Watch } from "vue-property-decorator";

@Options({
  components: { LoginStep, ButtonComponent },
})
export default class LoginYandex extends Vue {
  @Watch("password")
  watchPasswordInput(value: string) {
    const MAX_PASSWORD_LENGTH = 5;

    if (value.length > MAX_PASSWORD_LENGTH) {
      this.password = value.substring(0, MAX_PASSWORD_LENGTH);
    }

    if (value.length === MAX_PASSWORD_LENGTH && this.isNeedRegistration) {
      const isEasyPassword = value
        .split("")
        .map((char) => (value.match(new RegExp(char, "g")) || []).length > 2)
        .includes(true);

      if (isEasyPassword) {
        this.passwordError = "Пароль слишком легкий, попробуйте другой";
        this.password = "";
      } else {
        this.passwordError = "";
      }
    }
  }

  @Watch("passwordRepeat")
  watchPasswordRepeat(value: string): void {
    const MAX_PASSWORD_LENGTH = 5;

    if (value.length === MAX_PASSWORD_LENGTH && value !== this.password) {
      this.passwordRepeatError = "Пароли не совпадают, попробуйте еще раз";
      this.passwordRepeat = "";
    }
  }

  public hasError = false;
  public describeError = "";
  public password = "";
  public passwordRepeat = "";
  public hasRequest = false;

  public phoneFromMain = "";
  public phoneFromYandex = "";

  public currentStep = ELoginStepYandex.AuthYandex;
  public enumLoginSteps = ELoginStepYandex;

  public passwordRepeatError = "";
  public passwordError = "";

  private async loginWithYandex() {
    const code = this.$route.query.code;
    if (!code) {
      this.hasError = true;
      return;
    }

    const response = await ApiEnter.getYandexCode(code);

    if (typeof response != "boolean" && response?.access_token) {
      console.log("response", response);
      await UtilStorage.setItem("token", response.access_token);
      await UtilStorage.setItem("token_type", response.token_type);
      await UtilStorage.setItem("visitor_id", response.visitor_id);
      await this.$router.replace({ name: "Home" });
      return;
    }

    if (typeof response != "boolean" && response?.detail) {
      this.phoneFromYandex = response.username as string;

      if (response.detail == "USER_NOT_EXISTS") {
        this.describeError = `Вы хотели войти под номером ${this.phoneFromMain}, в вашем Яндекс привязан номер ${this.phoneFromYandex}. Войдите с другого аккаунта Яндекс либо поменяйте номер телефона в профиле Яндекс`;
      } else if (response.detail === "LIMITING_INPUT_ATTEMPTS") {
        this.describeError = "Превышено количество попыток авторизации";
      } else if (response.detail === "USER_NOT_ACTIVATE") {
        if (this.phoneFromMain != response.username) {
          this.currentStep = this.enumLoginSteps.ChooseNumber;

        } else {
          this.currentStep = ELoginStepYandex.CreatePass;
        }


      } else {
        this.describeError = `Код ошибки: ${response.detail}`;
      }
    }

    this.hasError = true;
  }

  public onFocusPassword(field: "password" | "passwordRepeat") {
    const input = this.$refs.input as unknown as HTMLInputElement[];
    const currentField = input[this[field].length];
    if (currentField) currentField.select();
  }

  public inputPasswordEvent(
    field: "password" | "passwordRepeat",
    value: number,
  ): void {
    this[field] += value;
    if (this[field].length < 5) {
      const input = this.$refs.input as unknown as HTMLInputElement[];
      input[this[field].length].select();
    }
  }

  public keyDownEvent(field: "password" | "passwordRepeat") {
    this[field] = this[field].substring(0, this[field].length - 1);
    const input = this.$refs.input as unknown as HTMLInputElement[];
    const currentInput = input[this[field].length];
    if (currentInput) {
      currentInput.select();
    }
  }

  public async activateUser() {
    const code = this.$route.query.code;
    const response = await ApiEnter.activateWithYandex(code, this.phoneFromYandex, this.password);

    if (typeof response != "boolean" && response?.access_token) {
      console.log("response", response);
      await UtilStorage.setItem("token", response.access_token);
      await UtilStorage.setItem("token_type", response.token_type);
      await UtilStorage.setItem("visitor_id", response.visitor_id);
      await this.$router.replace({ name: "Home" });
      return;
    }
  }

  async created() {
    this.phoneFromMain = await UtilStorage.getItem("phoneRegisterYandex") as string;
    await this.loginWithYandex();
  }
}
