
import { Options, Vue } from "vue-class-component";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import PageHeader from "@/components/Common/PageHeader.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import UtilStorage from "@/util/Common/UtilStorage";
import ApiEnter from "@/api/ApiEnter";
import { Http } from "@capacitor-community/http";
import { Action } from "s-vuex-class";

@Options({
  components: {
    PageHeader,
    ButtonComponent,
    StandartTemplate,
    ConfirmModal,
    StatusModal,
  },
})
export default class SettingsPage extends Vue {
  @Action("logout") private clearStore: (() => void) | undefined;
  private confirmModalDialog = false;
  private statusModalDialog = false;

  public redirectToWiki() {
    window.open("https://help.force2.ru", "_blank");
  }

  private tabs: { title: string; route: string }[] = [
    // {
    //   title: "Контакты",
    //   route: "",
    // },
    // {
    //   title: "История входов",
    //   route: "login-history",
    // },
    // {
    //   title: "Восстановить пароль",
    //   route: "",
    // },
  ];

  private async logout() {
    this.statusModalDialog = false;
    UtilStorage.removeItem("phone");
    await ApiEnter.logout();
    this.clearStore?.();
    this.$router.replace("/");
    if (this.$PLATFORM != "web") {
      Http.clearCookies({
        url: process.env.VUE_APP_BASE_URL,
      });
    }
  }
}
