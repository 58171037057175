
import { Options, Vue } from "vue-class-component";

import NoInternet from "@/components/Statuses/NoInternet.vue";
import ErrorModal from "@/components/Modals/ErrorModal.vue";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Mutation } from "s-vuex-class";
import InstallModal from "@/components/Modals/InstallModal.vue";
import UtilStorage from "@/util/Common/UtilStorage";

@Options({
  components: { InstallModal, NoInternet, ErrorModal },
})
export default class App extends Vue {
  @Mutation("setDeferredPrompt") private setDeferredPrompt:
    | ((value: any) => void)
    | undefined;
  public navigationBottomPadding = 10;
  private iosPlatformHeight: string | null = null;
  private navigationTabs: { icon: string; route: string; title: string }[] = [
    {
      icon: "fas fa-home",
      route: "/home",
      title: "Главная",
    },
    {
      icon: "fas fa-bell",
      route: "/notifications",
      title: "Уведомления",
    },
    {
      icon: "fas fa-user",
      route: "/profile",
      title: "Профиль",
    },
    {
      icon: "fas fa-cog",
      route: "/settings",
      title: "Настройки",
    },
  ];

  get layout() {
    return this.$route.meta.layout || false;
  }

  get currentRoute() {
    return this.$route.path;
  }

  private onClickTab(link: string) {
    this.$router.replace(link);
  }

  private isNeedBottomPadding() {
    if (this.$PLATFORM === "ios") {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        this.iosPlatformHeight = insets.top + "px";
      });
      return false;
    }

    if (this.$IS_PWA && this.$IS_SAFARI) {
      return false;
    }

    return true;
  }

  created() {
    if (this.$PLATFORM == "web") {
      window.addEventListener("beforeinstallprompt", (event) => {
        // Prevent the default behavior of the event
        event.preventDefault();
        // Save the event for later
        this.setDeferredPrompt?.(event);
        UtilStorage.removeItem("appInstalled");
      });
    }
  }
}
