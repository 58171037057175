export interface INotification {
  id: string;
  amount: number;
  date_time: string;
  status: ENotificationStatus;
  card_number: string;
  commission_withheld: number;
  status_description: string;
  status_payout: string;
  cabinet_driver_id: string;
  card_name: string;
  bank: string;
}

export interface INotificationsResponse {
  count: number;
  results: INotification[];
  limit: number;
  skip: number;
}

export enum ENotificationStatus {
  Paid = "PAID",
  Process = "IN_PROCESSING",
  Error = "ERROR",
}
