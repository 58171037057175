
import { Options, Vue } from "vue-class-component";

import UserInfoSkeleton from "@/components/Skeleton/Home/UserInfoSkeleton.vue";
import { Action, Getter } from "s-vuex-class";
import { IUserData } from "@/struct/IStore/IStore";
import { App } from "@capacitor/app";

@Options({
  components: { UserInfoSkeleton },
})
export default class UserInfoComponent extends Vue {
  @Action("getUserData") private getUserData: (() => void) | undefined;

  @Getter("getUserData") private userData: IUserData | undefined;

  public appVersion: string | null = null;

  async created() {
    if (!this.userData) {
      this.getUserData?.();
    }

    if (this.$PLATFORM !== "web") {
      const appInfo = await App.getInfo();
      this.appVersion = `v${appInfo.version} ${appInfo.build}`;
    }
  }
}
