
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";

@Options({
  components: { ButtonComponent },
})
export default class ConfirmModal extends Vue {
  @Prop() public state!: boolean;
  @Prop() public description!: string[];
  @Prop() public htmlDescription!: string;
  @Prop() public confirmButtonText!: string;
  @Prop() public declineButtonText!: string;
  @Prop() public modelValue!: boolean;

  @Watch("state")
  watchModalState(new_value: boolean) {
    if (new_value) {
      return document.body.classList.add("overflow-hidden");
    }
    if (!new_value) {
      return document.body.classList.remove("overflow-hidden");
    }
  }

  @Watch("modelValue")
  watchModalValue(new_value: boolean) {
    if (new_value) {
      return document.body.classList.add("overflow-hidden");
    }
    if (!new_value) {
      return document.body.classList.remove("overflow-hidden");
    }
  }

  private onClickOutside() {
    const dialog = document.getElementById("dialog") as HTMLDivElement;
    dialog.classList.add("bounce-modal-animation");
    setTimeout(() => {
      dialog.classList.remove("bounce-modal-animation");
    }, 100);
  }
}
