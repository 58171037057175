
import PageHeader from "@/components/Common/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import { Options, Vue } from "vue-class-component";
import InputComponent from "@/components/Common/InputComponent.vue";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import ApiCard from "@/api/ApiCard";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import { Toast } from "@capacitor/toast";
import { toast } from "vue3-toastify";
import { Watch } from "vue-property-decorator";
import ApiEnter from "@/api/ApiEnter";
import { Action } from "s-vuex-class";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  IBankIcons,
  IPopularSBPBank,
  ISBPBank,
} from "@/struct/IAddCard/IAddCard";
import UtilError from "@/util/ValidateError/UtilError";
import ChooseSBPBank from "@/components/Modals/ChooseSBPBank.vue";
import SBPIcon from "@/components/Icons/SBPIcon.vue";
import CardIcon from "@/components/Icons/CardIcon.vue";
import WikiComponent from "@/components/Wiki/WikiComponent.vue";

@Options({
  components: {
    WikiComponent,
    CardIcon,
    SBPIcon,
    ChooseSBPBank,
    StatusModal,
    ConfirmModal,
    ButtonComponent,
    InputComponent,
    StandartTemplate,
    PageHeader,
  },
})
export default class AddCard extends Vue {
  @Action("fetchUserCards") private fetchUserCards: (() => void) | undefined;

  @Watch("cardNumber")
  validateCardNumber() {
    const cardNumber = this.cardNumber.replace(/\s/g, "");
    const checkCard = (cardNo: string) => {
      let s = 0;
      let doubleDigit = false;
      for (let i = cardNo.length - 1; i >= 0; i--) {
        let digit = +cardNo[i];
        if (doubleDigit) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
        s += digit;
        doubleDigit = !doubleDigit;
      }
      return s % 10 == 0;
    };

    this.validateCard =
      (cardNumber.length === 16 ||
        cardNumber.length === 19 ||
        cardNumber.length === 18) &&
      checkCard(cardNumber);
  }

  removeNumberInName(field: "cardName" | "sbpCardName") {
    this[field] = this[field].replace(/[^a-zA-Zа-яА-Я ]/g, "");
  }

  get allBanksListFilter() {
    return this.allBanksList?.filter((item) =>
      item.bank_name.toLowerCase().includes(this.bankFilter.toLowerCase()),
    );
  }

  private isAddCard = false;
  private isSendOtp = false;
  private cardName = "";
  private cardNumber = "";
  private otpNumber = "";
  private hasRequest = false;
  private stateSuccessAddCard = false;
  private validateCard = false;
  private nextCallTime = 60;
  private callTries = 0;

  private typeOtp: "phone" | "telegram" = "phone";

  private isAddSBP = false;
  private isSendOtpSbp = false;
  private sbpNumber = "";
  private sbpCardName = "";

  private mainBanksList: IPopularSBPBank[] = [];
  private chooseSbpBank: IPopularSBPBank | null = null;

  private allBanksList: ISBPBank[] = [];
  private bankFilter = "";
  private allBankDialog = false;
  private otherBank: ISBPBank | null = null;

  public async onSubmitNewSbp() {
    if (!this.isBankChosen()) {
      UtilError.validateErrorMessage("sbp_bank_not_chosen");
      return;
    }

    if (this.isCardNameTooShort(this.sbpCardName)) {
      UtilError.validateErrorMessage("short_card_name");
      return;
    }

    this.removeNumberInName("sbpCardName");

    const bankIdentifier = this.getSelectedBankIdentifier();
    const phoneNumber = this.formatPhoneNumber(this.sbpNumber);
    const sbpCardName = this.normalizeCardName(this.sbpCardName);

    this.hasRequest = true;
    const response = await ApiCard.createSBPCard(
      sbpCardName,
      phoneNumber,
      bankIdentifier,
    );
    this.processApiResponse(response, "sbp");
    this.hasRequest = false;
  }

  public async onSubmitNewCard() {
    if (this.isCardNameTooShort(this.cardName)) {
      UtilError.validateErrorMessage("short_card_name");
      return;
    }
    this.removeNumberInName("cardName");
    const cardNumber = this.normalizeCardNumber(this.cardNumber);
    const cardName = this.normalizeCardName(this.cardName);

    this.hasRequest = true;
    const response = await ApiCard.createCard(cardName, cardNumber);
    this.processApiResponse(response, "regular");
    this.hasRequest = false;
  }

  private processConfirmation(response: any, type: "sbp" | "regular") {
    if (response.otp_status?.detail === "TM_SEND") {
      this.typeOtp = "telegram";
    }
    this[`isSendOtp${type === "sbp" ? "Sbp" : ""}`] = true;
    this.callTries += 1;
    this.startOTPInterval();
  }

  private resetCardName() {
    this.cardName = "";
  }

  private processApiResponse(response: any, type: "sbp" | "regular") {
    if (
      response?.detail === "DRIVER_SBP_ALREADY_EXIST" ||
      response?.detail === "DRIVER_CARD_ALREADY_EXIST"
    ) {
      this.resetCardName();
      UtilError.validateErrorMessage(response.detail);
    } else if (
      response?.detail === "DRIVER_SBP_CREATE_CONFIRMATION" ||
      response?.detail === "DRIVER_CARD_CREATE_CONFIRMATION"
    ) {
      this.processConfirmation(response, type);
    }
  }

  private toggleFormSBP(): unknown {
    this.cardNumber = "";
    this.cardName = "";
    this.sbpCardName = "";
    this.sbpNumber = "";
    this.chooseSbpBank = null;
    this.isAddSBP = true;
    if (this.isAddCard) {
      return (this.isAddCard = false);
    }
  }
  private toggleFormCard(): unknown {
    this.cardNumber = "";
    this.cardName = "";
    this.sbpCardName = "";
    this.sbpNumber = "";
    this.chooseSbpBank = null;
    this.isAddCard = true;
    if (this.isAddSBP) {
      return (this.isAddSBP = false);
    }
  }

  private isBankChosen(): boolean {
    return !!this.chooseSbpBank || !!this.otherBank;
  }

  private isCardNameTooShort(cardName: string): boolean {
    return cardName.trim().length < 3;
  }

  private getSelectedBankIdentifier(): string {
    return (
      this.chooseSbpBank?.identifier || (this.otherBank?.identifier as string)
    );
  }

  private formatPhoneNumber(number: string): string {
    return "+7" + number.replace(/\D/g, "");
  }

  private normalizeCardNumber(cardNumber: string): string {
    return cardNumber.split(" ").join("");
  }

  private normalizeCardName(cardName: string): string {
    return cardName.trim();
  }

  public onPaste(evt: ClipboardEvent) {
    if (!evt.clipboardData) {
      return;
    }

    let data = evt.clipboardData.getData("text").split("+").join("");
    data = data.split(" ").join("");

    if (data.length === 11 && ["7", "8"].includes(data.charAt(0))) {
      this.sbpNumber = data.substring(1);
    }
  }

  public chooseOtherBank(item: ISBPBank) {
    this.otherBank = item;
    this.chooseSbpBank = null;
    this.$refs.banksList.scrollLeft = 0;
    this.allBankDialog = false;

    if (this.$PLATFORM != "web") {
      Haptics.impact({ style: ImpactStyle.Light });
    }
  }

  public choosePopularBank(item: IPopularSBPBank) {
    this.chooseSbpBank = item;
    if (this.$PLATFORM != "web") {
      Haptics.impact({ style: ImpactStyle.Light });
    }
  }

  public validateSaveButtonName() {
    const cardNumber = this.cardNumber.split(" ").join("");
    const isValidLength = this.isValidCardLength(cardNumber.length);

    // Если длина номера карты валидна, возвращаем на основе валидности карты либо "Сохранить", либо "Неверный номер карты"
    if (isValidLength) {
      return this.validateCard ? "Сохранить" : "Неверный номер карты";
    }

    return this.cardNumber.length <= 0
      ? "Заполните данные"
      : "Неверный номер карты";
  }

  private isValidCardLength(length: number): boolean {
    return [16, 18, 19].includes(length);
  }

  public async codeConfirmation() {
    const INVALID_CONFIRMATION_CODE = "invalid_code";
    const DRIVER_CARD_CREATED = "card_created";

    try {
      this.hasRequest = true;
      const response = await ApiCard.codeConfirmation(this.otpNumber);

      // Обработка неправильного кода подтверждения
      if (response?.detail === INVALID_CONFIRMATION_CODE) {
        await this.handleInvalidCode();
        return;
      }

      // Обработка успешного создания карты водителя
      if (response?.detail === DRIVER_CARD_CREATED) {
        await this.handleSuccessAddCard();
        return;
      }
    } finally {
      // Гарантируем, что hasRequest будет сброшен в любом случае
      this.hasRequest = false;
    }
  }

  private startOTPInterval() {
    this.nextCallTime = 100;
    const otpInterval = setInterval(() => {
      this.nextCallTime -= 1;

      if (this.nextCallTime <= 0) {
        clearInterval(otpInterval);
      }
    }, 1000);
  }

  private async getAllBanksMembers() {
    const response: { identifier: string; bank_name: string }[] | boolean =
      await ApiCard.getSBPMembers();

    if (!Array.isArray(response)) return;

    const bankIcons: IBankIcons = {
      сбер: {
        icon: "sberbank",
        color: "#E5FFD9",
        displayName: "Сбер",
      },
      альфа: {
        icon: "alfa",
        color: "#FFDBD7",
        displayName: "Альфа",
      },
      тинькофф: {
        icon: "tinkoff",
        color: "#FFEDAA",
        displayName: "Тинькофф",
      },
    };

    const needBanksList = ["сбер", "альфа", "тинькофф"];

    this.allBanksList = response;

    response?.forEach((item) => {
      needBanksList?.forEach((bank) => {
        if (item.bank_name.toLowerCase().includes(bank.toLowerCase())) {
          const bankInfo = bankIcons[bank];
          if (bankInfo) {
            this.mainBanksList.push({
              ...item,
              ...bankInfo,
            });
          }
        }
      });
    });
  }

  // Обработка успешного добавления карты
  async handleSuccessAddCard() {
    this.resetCardInfo();
    if (this.$PLATFORM !== "web") {
      Haptics.impact({ style: ImpactStyle.Light });
    }
  }

  async handleInvalidCode() {
    this.otpNumber = "";
    if (this.$PLATFORM != "web") {
      await Toast.show({
        text: "Введен неправильный код, попробуйте еще раз!",
      });
    } else {
      await toast("Введен неправильный код, попробуйте еще раз!", {
        autoClose: 3000,
        type: "warning",
      });
    }
  }

  private routeBack() {
    this.fetchUserCards?.();
    this.stateSuccessAddCard = false;
    this.$router.push("/home");
  }

  private resetCardInfo() {
    this.stateSuccessAddCard = true;
    this.isAddCard = false;
    this.isSendOtp = false;
    this.cardName = "";
    this.cardNumber = "";
    this.otpNumber = "";
  }

  created() {
    this.getAllBanksMembers();

    if (this.$route.query?.open) {
      if (this.$route.query?.open == "sbp") {
        this.isAddSBP = true;
      }

      if (this.$route.query?.open == "card") {
        this.isAddCard = true;
      }
    }
  }
}
