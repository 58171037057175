
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class WikiComponent extends Vue {
  @Prop({ default: "https://help.force2.ru" }) public link!: string;
  @Prop({ default: "Возникли трудности?" }) public title!: string;
  @Prop({ default: "Нажмите сюда и мы поможем" }) public subtitle!: string;

  public redirectToWiki() {
    window.open(this.link, "_blank");
  }
}
