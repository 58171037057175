
import { Vue, Options } from "vue-class-component";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import confetti from "canvas-confetti";

@Options({})
export default class SuccessInstall extends Vue {
  public isLoading = false;

  mounted() {
    if (this.$IS_PWA) {
      this.$router.replace("/");
      return;
    }
    const myCanvas = document.createElement("canvas");
    const confettiEl = document.getElementById("confetti") as HTMLElement;
    confettiEl.appendChild(myCanvas);
    const myConfetti = confetti.create(myCanvas, { resize: true });

    setTimeout(() => {
      this.isLoading = true;
      myConfetti({ particleCount: 200 });
    }, 8000);
  }

}
