
import StoryViewer from "@/components/Stories/StoryViever.vue";
import StoryItem from "@/components/Stories/StoryItem.vue";
import { Vue, Options } from "vue-class-component";
import { IStory } from "@/struct/IStory/IStory";
import * as Sentry from "@sentry/vue";

@Options({
  components: {
    StoryItem,
    StoryViewer,
  },
})
export default class StoryList extends Vue {
  private stories: IStory[] = [
    {
      title: "Вывод СБП",
      previewImage: require("@/assets/img/story/sbp/preview.svg"),
      slides: [
        {
          image: require("@/assets/img/story/sbp/slide1.png"),
          title: "Самые низкие комиссии",
          description: "Наша новая функция вывода через систему быстрых платежей обеспечивает наименьшие возможные комиссии. \n\n Экономьте больше с каждым переводом!",
        },
        {
          image: require("@/assets/img/story/sbp/slide2.png"),
          title: "Ускоренные переводы",
          description: "Благодаря новой системе быстрых платежей, ваши переводы осуществляются мгновенно. \n\n Больше не нужно ждать получения своих средств!",
        },
        {
          image: require("@/assets/img/story/sbp/slide3.png"),
          title: "Простота в  \n добавлении",
          description: "Добавление и использование новой системы быстрых платежей в нашем приложении легко и удобно.",
          buttonText: "Подключить СБП",
          buttonAction: "redirectToSbp",
        },
      ],
    },
  ];


  private activeStoryIndex: number | null = null;

  private closeStory() {
    this.activeStoryIndex = null;
  }

  private handeStoryAction(action: string) {
    if (this[action]) {
      // Вызываем функцию из слайда
      this[action]();
    }
  }

  private redirectToSbp() {
    this.closeStory();
    this.$router.push({ path: "/new-card", query: { "open": "sbp" } });
  }
}
