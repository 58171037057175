
import { Options, Vue } from "vue-class-component";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";

@Options({
  components: { StandartTemplate },
})
export default class SupportPage extends Vue {
  private faqTabs: { title: string; answer: string; isOpen: boolean }[] = [
    {
      title: "Как удалить карту",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
    {
      title: "Почему не выводятся деньги",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
    {
      title: "Как получить бонусы",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
    {
      title: "Как добавить карту",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
    {
      title: "Как включить уведомления",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
  ];
}
