import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StoryItem = _resolveComponent("StoryItem")!
  const _component_StoryViewer = _resolveComponent("StoryViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stories, (story, index) => {
      return (_openBlock(), _createBlock(_component_StoryItem, {
        key: index,
        title: story.title,
        story: story,
        onClick: ($event: any) => (_ctx.activeStoryIndex = index)
      }, null, 8, ["title", "story", "onClick"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stories, (story, index) => {
      return (_openBlock(), _createBlock(_component_StoryViewer, {
        key: index,
        story: story,
        isActive: _ctx.activeStoryIndex === index,
        onEnd: _ctx.closeStory,
        "is-background": story.onlyBackground,
        onStoryActionClick: _ctx.handeStoryAction
      }, null, 8, ["story", "isActive", "onEnd", "is-background", "onStoryActionClick"]))
    }), 128))
  ]))
}