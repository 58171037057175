
import { Prop } from "vue-property-decorator";
import { Vue } from "vue-class-component";

export default class PayCards extends Vue {
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) subtitle!: string;
  @Prop() cardId!: string;
  @Prop({ default: "" }) numberCard!: string | number;
  @Prop({ default: "" }) rent!: string;
  @Prop({ default: "" }) cash!: string;
  @Prop({ default: "" }) color!: string;
  @Prop({ default: false }) isActive!: boolean;
  @Prop({ default: true }) checkIcon!: boolean;
  @Prop({ default: "" }) type!: string;

  private editCard() {
    this.$router.push("/edit-card/" + this.cardId);
  }

  private validateCardIcon() {
    if (this.type === "Tinkoff") {
      return "tinkoff-card";
    }

    if (this.type === "Sbp") {
      return "sbp";
    }
    return "card";
  }
}
