import { createStore } from "vuex";
import { IStoreState, IUserData } from "@/struct/IStore/IStore";
import { ILinkSupport } from "@/struct/ILinkSupport/ILinkSupport";
import ApiEnter from "@/api/ApiEnter";
import { ICard } from "@/struct/IHomePage/ICabinetBalance";
import ApiCard from "@/api/ApiCard";
import { ELoginStep } from "@/struct/ELogin/ELoginStep";
import ApiLinkSupport from "@/api/ApiLinkSupport";

export default createStore({
  state: {
    connectionState: true,
    userData: null,
    linkSupport: null,
    userCards: undefined,
    deferredPrompt: null,
    currentLoginStep: ELoginStep.EnterPhone,
    isNeedRegistration: false,
    loginPhone: "",
    loginPassword: "",
  },
  getters: {
    getConnectionState(state: IStoreState): boolean {
      return state.connectionState;
    },
    getUserData(state: IStoreState): IUserData | null {
      return state.userData;
    },
    getLinkSupport(state: IStoreState): ILinkSupport | null {
      return state.linkSupport;
    },
    getUserCards(state: IStoreState): ICard[] | undefined {
      return state.userCards;
    },
    getDeferredPromo(state: IStoreState): any {
      return state.deferredPrompt;
    },
    getCurrentLoginStep(state: IStoreState): ELoginStep {
      return state.currentLoginStep;
    },
    getLoginPhone(state: IStoreState): string {
      return state.loginPhone;
    },
    getLoginPassword(state: IStoreState): string {
      return state.loginPassword;
    },
    getIsNeedRegistration(state: IStoreState): boolean {
      return state.isNeedRegistration;
    },
  },
  mutations: {
    changeConnectionState(state: IStoreState, value: boolean): void {
      state.connectionState = value;
    },
    setUserData(state: IStoreState, data: IUserData): void {
      state.userData = data;
    },
    setLinkSupport(state: IStoreState, data: ILinkSupport): void {
      state.linkSupport = data;
    },
    setUserCards(state: IStoreState, data: ICard[]): void {
      state.userCards = data;
    },
    setDeferredPrompt(state: IStoreState, data: any) {
      state.deferredPrompt = data;
    },
    setCurrentStep(state: IStoreState, data: ELoginStep) {
      state.currentLoginStep = data;
    },
    setLoginPhone(state: IStoreState, value: string) {
      state.loginPhone = value;
    },
    setLoginPassword(state: IStoreState, value: string) {
      state.loginPassword = value;
    },
    setIsNeedRegistration(state: IStoreState, value: boolean) {
      state.isNeedRegistration = value;
    },
  },
  actions: {
    async getUserData(context) {
      const response = await ApiEnter.getAccountUser();
      if (typeof response != "boolean") context.commit("setUserData", response);
    },

    async getLinkSupport(context) {
      const response = await ApiLinkSupport.getLinkSupport();
      if (typeof response != "boolean")
        context.commit("setLinkSupport", response);
    },

    async fetchUserCards(context) {
      const response = await ApiCard.getCards();
      context.commit("setUserCards", response);
    },
    async logout(context) {
      context.commit("setUserData", null);
      context.commit("setUserCards", undefined);
    },
  },
  modules: {},
});
