
import { Vue } from "vue-class-component";
import { Getter, Mutation } from "s-vuex-class";
import * as Sentry from "@sentry/vue";

export default class AppleStore extends Vue {
  @Getter("getDeferredPromo") private deferredPrompt: any;
  @Mutation("setDeferredPrompt") private setDeferredPrompt:
    | ((value: any) => void)
    | undefined;

  public isWebview = this.$IS_WEBVIEW;

  async sendPromt() {
    // When the user clicks the button, show the installation prompt
    if (this.deferredPrompt) {
      // Show the installation prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt

      const { outcome } = await this.deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
        Sentry.captureMessage("Success install online app Android");
      } else {
        console.log("User dismissed the install prompt");
      }
      // Clear the deferredPrompt variable since it can only be used once
      this.setDeferredPrompt?.(null);
    } else {
      const gif = document.getElementById("gif");

      (gif as HTMLElement).scrollIntoView({ behavior: "smooth" });
    }
  }

  public openWhatsApp() {
    const phoneNumber = encodeURI(process.env.VUE_APP_WHATSAPP_NUMBER);
    let message = `Проблема с установкой приложения через онлайн магазин. IOS. `;
    message = encodeURI(message);
    window.open(`https://wa.me/${phoneNumber}?text=${message}`);
  }

  created() {
    if (this.$IS_PWA) {
      this.$router.replace("/");
    }
  }
}
