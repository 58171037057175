
import { Options, Vue } from "vue-class-component";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import BlurComponent from "@/components/BlurComponent/BlurComponent.vue";
import { Action, Getter } from "s-vuex-class";
import { IUserData } from "@/struct/IStore/IStore";
import ProfilePageSkeleton from "@/components/Skeleton/ProfilePageSkeleton.vue";

@Options({
  components: { ProfilePageSkeleton, BlurComponent, StandartTemplate },
})
export default class ProfilePage extends Vue {
  @Getter("getUserData") private userData: IUserData | undefined;
  @Action("getUserData") private getUserData: (() => void) | undefined;

  created() {
    if (!this.userData) {
      this.getUserData?.();
    }
  }
}
