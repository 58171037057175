
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class InputComponent extends Vue {
  @Prop({ default: 64 }) maxlength!: number;
  @Prop() model!: string;
  @Prop({ default: "text" }) type!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: "" }) prefix!: string;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "h-12" }) height!: string;
  @Prop({ default: "bg-background" }) background!: string;
  @Prop({ default: "sm" }) rounded!: string;
  @Prop({ default: true }) border!: boolean;
  @Prop({ default: "on" }) autocomplete!: string;
  @Prop() mask!: string;
}
