
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter } from "s-vuex-class";

export default class ButtonComponent extends Vue {
  @Getter("getConnectionState") private connectionState: boolean | undefined;

  @Prop({ default: "" }) title!: string;
  @Prop({ default: "primary" }) color!: string;
  @Prop({ default: "grey" }) textColor!: string;
  @Prop({ default: "sm" }) rounded!: string;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: "" }) font!: string;
  @Prop({ default: "" }) type!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop() disabledText!: string;
  @Prop({ default: "14" }) height!: string;
  @Prop({ default: true }) fullWidth!: boolean;
  @Prop({ default: "" }) inputClass!: string;
  @Prop({ default: false }) whatsappIcon!: boolean;

  get buttonClasses() {
    return [
      "relative",
      "w-full",
      "select-none",
      "overflow-hidden",
      "px-6",
      "text-center",
      "transition-all",
      "duration-300",
      `rounded-${this.rounded}`,
      this.textColor,
      `h-${this.height}`,
      `font-${this.font}`,
      `bg-${this.color}`,
      this.fullWidth ? "w-full" : "w-max",
      `text-${this.textColor}`,
      this.inputClass,
      this.disabled ? "opacity-80" : "opacity-100",
    ];
  }

  private addRippleEffect() {
    const buttons = document.querySelectorAll<HTMLElement>("#buttonComponent");
    if (buttons) {
      buttons.forEach((button) => {
        button.addEventListener("click", (e: MouseEvent) => {
          const x = e.clientX - (e.target as HTMLInputElement).offsetLeft;
          const ripples = document.createElement("span");
          ripples.style.left = x + "px";
          ripples.className = "animate-btn";
          button.appendChild(ripples);
          setTimeout(() => {
            ripples.remove();
          }, 1000);
        });
      });
    }
  }

  mounted() {
    this.addRippleEffect();
  }
}
