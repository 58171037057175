
import { Options, Vue } from "vue-class-component";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import ApiNotifications from "@/api/ApiNotifications";
import {
  ENotificationStatus,
  INotification,
} from "@/struct/INotifications/INotification";
import { ILinkSupport } from "@/struct/ILinkSupport/ILinkSupport";
import dayjs from "dayjs";
import NotificationPageSkeleton from "@/components/Skeleton/NotificationPageSkeleton.vue";
import { Action, Getter } from "s-vuex-class";
import { IUserData } from "@/struct/IStore/IStore";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import { App } from "@capacitor/app";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import StatusModal from "@/components/Modals/StatusModal.vue";

dayjs.extend(duration);
dayjs.extend(utc);

@Options({
  components: {
    StatusModal,
    ConfirmModal,
    NotificationPageSkeleton,
    StandartTemplate,
  },
})
export default class NotificationsPage extends Vue {
  @Action("getUserData") private getUserData: (() => void) | undefined;
  @Getter("getUserData") private userData: IUserData | undefined;
  @Action("getLinkSupport") private getLinkSupport: (() => void) | undefined;
  @Getter("getLinkSupport") private linkSupport: ILinkSupport[] | undefined;

  public waSupport!: string;
  private tab: "notification" | "history" = "notification";
  private isLoadData = false;
  private count = 0;
  private offset = 0;
  private loadNotificationInterval: number | null = null;
  private notifications: INotification[] = [];
  private currentNotification: INotification | null = null;
  private appVersion: string | null = null;

  private helpDialogModal = false;
  private disabledDialogModal = false;

  private statusModalDialog = false;
  private statusModalText = "";

  private async loadData() {
    const response = await ApiNotifications.getNotifications(this.offset, 50);
    if (typeof response != "boolean") {
      if (response && response?.count && response?.results?.length > 0) {
        this.count = response.count;
        this.notifications = response.results;
      }
      this.isLoadData = true;
    }
  }

  private formatDate(date: string) {
    return dayjs(date).format("HH:mm DD.MM.YYYY");
  }

  private validateTransactionStatus(
    status: ENotificationStatus,
    type: "color" | "text",
  ) {
    if (status === ENotificationStatus.Paid) {
      return type === "color" ? "bg-green" : "Успешно";
    }

    if (status === ENotificationStatus.Process) {
      return type === "color" ? "bg-primary" : "Выполняется";
    }

    if (status === ENotificationStatus.Error) {
      return type === "color" ? "bg-red" : "Ошибка";
    }
  }

  private isActiveContactParent(dateString: string) {
    const date = dayjs(dateString);
    const now = dayjs();
    // Вычисляем разницу между датами
    const difference = now.diff(date);
    // Создаем длительность разницы в минутах
    const differenceInMinutes = dayjs.duration(difference).asMinutes();
    // Проверяем, прошло ли 15 минут
    return differenceInMinutes >= 15;
  }

  private validateNotComingMoney(notification: INotification) {
    if (!this.userData) this.getUserData?.();
    this.currentNotification = notification;
    this.helpDialogModal = true;
  }

  private async closeHelpDialogModal() {
    this.helpDialogModal = false;
  }
  private async waitingForPayment() {
    this.helpDialogModal = false;
    this.$router.push("/home");
  }

  private async sendWhatsAppMessage() {
    if (this.linkSupport) {
      this.linkSupport.forEach((el) => {
        if (
          el?.cabinet_driver_id == this.currentNotification?.cabinet_driver_id
        ) {
          this.waSupport = el.wa_support;
        }
      });
    }
    let phoneNumber;
    if (this.currentNotification?.status_payout === "SCORE_NOT_ENOUGH_MONEY") {
      phoneNumber = this.waSupport;
    } else {
      phoneNumber = encodeURI(process.env.VUE_APP_WHATSAPP_NUMBER);
    }

    const date = dayjs(this.currentNotification?.date_time).format(
      "DD-MM-YYYY HH:mm:ss",
    );

    let message;
    if (this.currentNotification?.status_payout === "SCORE_NOT_ENOUGH_MONEY") {
      message = encodeURI(
        `Проблема с переводом. ${this.userData?.full_name}. ${
          this.userData?.phone_number
        }. Сумма: ${this.currentNotification?.amount}, Номер карты: ${
          this.currentNotification?.card_number
        }, Дата: ${date}, Статус: ${this.validateTransactionStatus(
          this.currentNotification?.status as ENotificationStatus,
          "text",
        )}, Причина: На счёте партнёра («Парка») было недостаточно средств.`,
      );
    } else {
      message = encodeURI(
        `Проблема с переводом. ${this.userData?.full_name}. ${
          this.userData?.phone_number
        }. Сумма: ${this.currentNotification?.amount}, Номер карты: ${
          this.currentNotification?.card_number
        }, Дата: ${date}, Статус: ${this.validateTransactionStatus(
          this.currentNotification?.status as ENotificationStatus,
          "text",
        )}${
          this.currentNotification?.status_description
            ? `, ${this.currentNotification.status_description}`
            : ""
        }${
          this.currentNotification?.status_payout
            ? `, Причина:  ${this.currentNotification.status_payout}.`
            : ""
        }`,
      );
    }

    window.open(`https://wa.me/${phoneNumber}?text=${message}`);
    this.helpDialogModal = false;
  }

  private mathCommission(amount: number, commission: number) {
    const result = amount - commission;

    // Проверка в конце ноль или нет
    if (result % 1 === 0) {
      return result.toFixed(0);
    } else {
      return result.toFixed(1);
    }
  }

  created() {
    setTimeout(() => {
      if (!this.linkSupport) {
        this.getLinkSupport?.();
      }
      this.loadData();

      this.loadNotificationInterval = setInterval(() => {
        if (this.$route.name != "NotificationsPage") {
          clearInterval(this.loadNotificationInterval as number);
          return;
        }

        this.loadData();
      }, 15000);
    }, 2000);
    if (this.$PLATFORM != "web") {
      App.getInfo().then((appInfo) => {
        this.appVersion = `v${appInfo.version} ${appInfo.build}`;
      });
    }
  }

  unmounted() {
    clearInterval(this.loadNotificationInterval as number);
  }
}
