
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class CheckboxComponent extends Vue {
  @Prop({ default: false }) model!: boolean;
  @Prop() label!: string;
  @Prop({ default: false }) disabled!: boolean;

  private onCheckboxChange(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      this.$emit("update", true);
    } else {
      this.$emit("update", false);
    }
  }
}
