
import { Vue } from "vue-class-component";
import { Network } from "@capacitor/network";
import { Getter, Mutation } from "s-vuex-class";

export default class NoInternet extends Vue {
  @Mutation("changeConnectionState") private changeConnectionState:
    | ((value: boolean) => void)
    | undefined;
  @Getter("getConnectionState") private statusConnection: boolean | undefined;

  private connectionBack = false;

  async watchConnection() {
    if (this.$PLATOFRM != "web") {
      Network.addListener("networkStatusChange", () => {
        setTimeout(async () => {
          const connection = await Network.getStatus();

          if (!connection.connected) {
            if (this.changeConnectionState) {
              this.changeConnectionState(false);
            }
          }

          if (connection.connected) {
            if (!this.statusConnection) {
              if (this.changeConnectionState) {
                this.changeConnectionState(true);
              }
              this.connectionBack = true;

              setTimeout(() => {
                this.connectionBack = false;
              }, 3000);
            }
          }
        }, 3000);
      });

    } else {

      const handleConnectionChange = () => {

        const online = navigator.onLine;

        if (!online) {
          // Offline
          updateNetworkStatus(false);

        } else {
          // Back online
          updateNetworkStatus(true);

          // Only trigger reconnection logic if
          // we've been offline for a bit
          if (!this.connectionBack) {
            this.connectionBack = true;

            setTimeout(() => {
              this.connectionBack = false;
            }, 3000);
          }
        }
      };
      // Listen for online/offline events
      window.addEventListener("online", handleConnectionChange);
      window.addEventListener("offline", handleConnectionChange);

      // Check connection on load
      handleConnectionChange();


      // Notify app of connection change
      const updateNetworkStatus = (isOnline: boolean) => {
        if (this.changeConnectionState) {
          this.changeConnectionState(isOnline);
        }
      };
    }
  }

  created() {
    this.watchConnection();
  }
}
