
import { Vue } from "vue-class-component";
import { Getter, Mutation } from "s-vuex-class";
import UtilStorage from "@/util/Common/UtilStorage";
import * as Sentry from "@sentry/vue";

export default class InstallModal extends Vue {
  @Getter("getDeferredPromo") private deferredPrompt: any;
  @Mutation("setDeferredPrompt") private setDeferredPrompt:
    | ((value: any) => void)
    | undefined;

  private windowLocation: Location | string = "";
  private isInstalled = false;
  private isSafari = false;
  public currentBrowser = "";

  private showApp() {
    if (this.$route.query["show-app"] == "show") {
      document.body.classList.add("overflow-hidden");
    } else {
      if (this.$route.query["show-app"] == "show") {
        document.body.classList.remove("overflow-hidden");
      }
    }
    return this.$route.query["show-app"] == "show";
  }

  private hideModal() {
    this.$router.replace({
      query: { "show-app": "hide" },
    });
    document.body.classList.remove("overflow-hidden");
  }

  async sendPrompt() {
    // When the user clicks the button, show the installation prompt

    if (this.deferredPrompt) {
      // Show the installation prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt

      const { outcome } = await this.deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
        await UtilStorage.setItem("appInstalled", "true");
        Sentry.captureMessage("Success install online app Android");
        this.isInstalled = true;
      } else {
        console.log("User dismissed the install prompt");
      }
      // Clear the deferredPrompt variable since it can only be used once
      this.setDeferredPrompt?.(null);
    }
  }

  public openWhatsApp(type: "Android" | "IOS") {
    const phoneNumber = encodeURI(process.env.VUE_APP_WHATSAPP_NUMBER);
    let message = `Проблема с установкой приложения через онлайн магазин. ${type}. `;
    message = encodeURI(message);
    window.open(`https://wa.me/${phoneNumber}?text=${message}`);
  }

  async created() {
    this.currentBrowser = this.$BROWSER_NAME;
    this.isSafari = this.$IS_SAFARI;
    this.windowLocation = window.location.hostname;

    const isInstalled = await UtilStorage.getItem("appInstalled");

    if (isInstalled) this.isInstalled = true;

    this.showApp();
  }
}
