
import { Options, Vue } from "vue-class-component";
import { Getter, Mutation } from "s-vuex-class";

import StatusModal from "@/components/Modals/StatusModal.vue";
import UtilStorage from "@/util/Common/UtilStorage";
import * as Sentry from "@sentry/vue";
import { UAParser } from "ua-parser-js";

@Options({
  components: { StatusModal },
})
export default class StoreApp extends Vue {
  @Getter("getDeferredPromo") private deferredPrompt: any;
  @Mutation("setDeferredPrompt") private setDeferredPrompt:
    | ((value: any) => void)
    | undefined;
  public modalState = false;
  public isInstalled = false;

  private currentBrowser = "";

  async sendPromt() {
    // When the user clicks the button, show the installation prompt

    if (this.currentBrowser.includes("Yandex")) {
      this.modalState = true;
      return;
    }

    let listOfInstalledApps;
    if ("getInstalledRelatedApps" in navigator) {
      listOfInstalledApps = await (navigator as any)?.getInstalledRelatedApps();
    }

    const isInstalledStore = await UtilStorage.getItem("appInstalled");

    if (isInstalledStore || listOfInstalledApps?.length > 0) this.isInstalled = true;


    if (this.deferredPrompt) {
      // Show the installation prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt

      const { outcome } = await this.deferredPrompt.userChoice;

      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
        Sentry.captureMessage("Success install online app Android");
        await UtilStorage.setItem("appInstalled", "true");
        this.isInstalled = true;
        this.$router.replace("/success-install");
      } else {
        console.log("User dismissed the install prompt");
      }

      // Clear the deferredPrompt variable since it can only be used once
      this.setDeferredPrompt?.(null);
    } else {
      this.modalState = true;
    }
  }


  mounted() {
    this.currentBrowser = new UAParser(navigator.userAgent).getBrowser().name as string;
    if (this.$IS_PWA) {
      this.$router.replace("/");
    }
    this.addRippleEffect();
  }

  private addRippleEffect() {
    const button: HTMLElement = document.getElementById(
      "storeBtn",
    ) as HTMLElement;

    button.addEventListener("click", (e: MouseEvent) => {
      let x = e.clientX - (e.target as HTMLInputElement).offsetLeft;
      let ripples = document.createElement("span");
      ripples.style.left = x + "px";
      ripples.className = "animate-btn";
      button.appendChild(ripples);
      setTimeout(() => {
        ripples.remove();
      }, 1000);
    });
  }
}
