
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class PageHeader extends Vue {
  @Prop() title!: string;
  @Prop() backUrl!: string;

  public routeBack() {
    if (this.backUrl) {
      this.$router.push(this.backUrl);
      return;
    }

    this.$router.push("/home");
  }
}
