
import { Options, Vue } from "vue-class-component";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import { App } from "@capacitor/app";

@Options({
  components: { ButtonComponent },
  watch: {
    state(value: boolean) {
      if (value) {
        return document.body.classList.add("overflow-hidden");
      }
      if (!value) {
        return document.body.classList.remove("overflow-hidden");
      }
    },
  },
})
export default class ErrorModal extends Vue {
  public visible = false;
  public errorMessage = "";
  public redirectLink = "";

  public appVersion: null | string = null;
  public type: "error" | "warning" = "error";

  async created() {
    (window as any).ErrorModal = this;
    if (this.$PLATFORM != "web") {
      const appInfo = await App.getInfo();
      this.appVersion = `v${appInfo.version} ${appInfo.build}`;
    }
  }

  public Show(
    errorMessage: string,
    type: "error" | "warning",
    redirectLink?: string,
  ) {
    try {
      this.type = type;
      this.redirectLink = "";
      this.errorMessage = errorMessage;
      this.visible = true;

      if (redirectLink) {
        this.redirectLink = redirectLink;
      }
    } catch (e) {
      console.error("error create", e);
    }
  }

  public onClickAction() {
    this.visible = false;
    if (this.redirectLink) window.open(this.redirectLink, "_blank");
  }

  public Hide() {
    this.errorMessage = "";
    this.visible = false;
  }

  private onClickOutside() {
    const dialog = document.getElementById("dialog") as HTMLDivElement;
    dialog.classList.add("bounce-modal-animation");
    setTimeout(() => {
      dialog.classList.remove("bounce-modal-animation");
    }, 100);
  }
}
