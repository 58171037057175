import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "theme-light" }
const _hoisted_2 = {
  key: 0,
  class: "navigation-area z-20 bg-greyLight"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text-[10px] text-grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_no_internet = _resolveComponent("no-internet")!
  const _component_error_modal = _resolveComponent("error-modal")!
  const _component_install_modal = _resolveComponent("install-modal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_no_internet),
    _createVNode(_component_error_modal),
    _createVNode(_component_install_modal),
    _createElementVNode("div", {
      class: "fixed top-0 z-[49] w-full bg-background",
      style: _normalizeStyle({ height: _ctx.iosPlatformHeight })
    }, null, 4),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_KeepAlive, { include: ['HomeView', 'LoginPage', 'TelegramBanner'] }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1024))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }),
    (_ctx.layout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationTabs, (tab) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_ctx.onClickTab(tab.route)),
              class: _normalizeClass(["tab", { 'tab-active': tab.route === _ctx.currentRoute }]),
              style: _normalizeStyle(
          _ctx.isNeedBottomPadding()
            ? `padding-bottom: ${_ctx.navigationBottomPadding}px`
            : ''
        ),
              key: tab.icon
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["tab-icon text-darkGrey", { 'text-greyLight': tab.route === _ctx.currentRoute }])
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(tab.icon)
                }, null, 2)
              ], 2),
              _createElementVNode("div", _hoisted_4, _toDisplayString(tab.title), 1)
            ], 14, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}