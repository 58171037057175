
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class LoginStep extends Vue {
  @Prop({ default: [] }) private title!: string[] | string;
  @Prop({ default: "" }) private subtitle!: string;
  @Prop({ default: "" }) private phone!: string;
  @Prop({ default: "" }) private attention!: string | null;

  private formatPhone() {
    const formattedPhone = this.phone.replace(/\D/g, "").split("");
    formattedPhone.splice(1, 0, " ");
    formattedPhone.splice(5, 0, " ");
    formattedPhone.splice(9, 0, " ");
    formattedPhone.splice(12, 0, " ");
    return formattedPhone.join("");
  }
}
