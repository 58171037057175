import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-5 flex justify-between text-grey items-center"
}
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "h-12 w-12 rounded-full flex justify-center items-center border-2 bg-greyLight text-grey text-xl" }
const _hoisted_4 = { class: "ml-4" }
const _hoisted_5 = { class: "font-medium flex space-x-2 items-center" }
const _hoisted_6 = { "data-cy": "userName" }
const _hoisted_7 = { "data-cy": "userPhone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_info_skeleton = _resolveComponent("user-info-skeleton")!
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    (_ctx.userData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.userData.full_name ? _ctx.userData.full_name.charAt(0) : "") + " ", 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.userData.full_name), 1)
              ]),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.userData.phone_number), 1)
            ])
          ])
        ]))
      : (_openBlock(), _createBlock(_component_user_info_skeleton, { key: 1 }))
  ])), [
    [_directive_auto_animate]
  ])
}