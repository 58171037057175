
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import { Options, Vue } from "vue-class-component";
import { ICard } from "@/struct/IHomePage/ICabinetBalance";
import PayCards from "@/components/HomePage/PayCard.vue";
import InputComponent from "@/components/Common/InputComponent.vue";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import PageHeader from "@/components/Common/PageHeader.vue";
import EditCardSkeleton from "@/components/Skeleton/EditCardSkeleton.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import ApiCard from "@/api/ApiCard";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Action, Mutation } from "s-vuex-class";
import UtilError from "@/util/ValidateError/UtilError";

@Options({
  components: {
    ConfirmModal,
    StatusModal,
    EditCardSkeleton,
    PageHeader,
    ButtonComponent,
    InputComponent,
    PayCards,
    StandartTemplate,
  },
})
export default class EditCard extends Vue {
  @Action("fetchUserCards") private fetchUserCards: (() => void) | undefined;
  @Mutation("setUserCards") private setUserCards:
    | ((value: undefined) => void)
    | undefined;

  private currentCardUUID!: string;
  private currentCard: ICard | null = null;
  public confirmModalDialog = false;
  public statusModalDialog = false;
  public saveModalDialog = false;
  public textModal: "сохранить" | "удалить" = "сохранить";
  private typeAction: "save" | "delete" = "save";
  public hasRequest = false;

  private onClickActionCard() {
    this.confirmModalDialog = false;

    if (this.typeAction === "save") {
      this.onClickSaveCard();
    }

    if (this.typeAction == "delete") {
      this.onClickDeleteCard();
    }
  }

  public onClickConfirm(type: "save" | "delete") {
    if (type === "save") {
      this.textModal = "сохранить";
      this.typeAction = "save";
    }

    if (type === "delete") {
      this.textModal = "удалить";
      this.typeAction = "delete";
    }

    this.confirmModalDialog = true;
  }

  public removeNumberInName() {
    if (!this.currentCard?.name) return;

    this.currentCard.name = this.currentCard.name.replace(
      /[^a-zA-Zа-яА-Я ]/g,
      "",
    );
  }

  private normalizeCardName(cardName: string): string {
    return cardName.trim();
  }

  private async onClickSaveCard() {
    if (!this.currentCard) return;

    this.hasRequest = true;
    const cardName = this.normalizeCardName(this.currentCard.name);
    const response = await ApiCard.updateCard(
      (this.currentCard as ICard).id as string,
      cardName,
    );

    if (response) {
      this.saveModalDialog = true;
      Haptics.impact({ style: ImpactStyle.Light });
    }
    this.hasRequest = false;
  }

  private async onClickDeleteCard() {
    if (!this.currentCard) return;

    this.hasRequest = true;
    const response = await ApiCard.deleteCard(this.currentCard.id as string);
    if (response.detail === "DRIVER_CARD_DELETED") {
      this.setUserCards?.(undefined);
      this.fetchUserCards?.();
      this.statusModalDialog = true;
      Haptics.impact({ style: ImpactStyle.Light });
    } else if (response.detail === "DRIVER_CARD_DELETE_ERROR") {
      this.setUserCards?.(undefined);
      this.fetchUserCards?.();
      UtilError.validateErrorMessage(response.detail);
      Haptics.impact({ style: ImpactStyle.Light });
    }
    this.hasRequest = false;
  }

  private async getCard() {
    const response = await ApiCard.GetCardByUUID(
      this.currentCardUUID as string,
    );
    if (typeof response != "boolean") {
      this.currentCard = response;
    }
  }

  created() {
    this.currentCardUUID = this.$route.params.cardId as string;
    this.getCard();
  }
}
