
import BalanceCard from "@/components/HomePage/BalanceCard.vue";
import { Options, Vue } from "vue-class-component";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import { ICard, ICabinet } from "@/struct/IHomePage/ICabinetBalance";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import PayCards from "@/components/HomePage/PayCard.vue";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import MainPageSkeleton from "@/components/Skeleton/CabinetsSkeleton.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import ApiHome from "@/api/ApiHome";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PullRefresh from "pull-refresh-vue3";
import { Action, Getter } from "s-vuex-class";
import UserCards from "@/components/Skeleton/Home/UserCardsSkeleton.vue";
import UtilError from "@/util/ValidateError/UtilError";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import ApiCard from "@/api/ApiCard";
import UserInfoComponent from "@/components/MainPage/UserInfoComponent.vue";
import * as Sentry from "@sentry/vue";
import UtilStorage from "@/util/Common/UtilStorage";
import TelegramBanner from "@/components/MainPage/TelegramBanner.vue";
import { Toast } from "@capacitor/toast";
import { toast } from "vue3-toastify";
import StoryList from "@/components/Stories/StoryList.vue";
import NewCardIcon from "@/components/Icons/NewCardIcon.vue";
import { IUserData } from "@/struct/IStore/IStore";
import { ILinkSupport } from "@/struct/ILinkSupport/ILinkSupport";
import store from "@/store";

@Options({
  components: {
    NewCardIcon,
    StoryList,
    TelegramBanner,
    UserInfoComponent,
    UserCards,
    ConfirmModal,
    StatusModal,
    MainPageSkeleton,
    ButtonComponent,
    PayCards,
    StandartTemplate,
    BalanceCard,
    Swiper,
    SwiperSlide,
    PullRefresh,
  },
  name: "HomeView",
})
export default class HomeView extends Vue {
  @Action("fetchUserCards") private fetchUserCards: (() => void) | undefined;
  @Getter("getUserCards") private payCardsData: ICard[] | undefined;
  @Action("getUserData") private getUserData: (() => void) | undefined;
  @Getter("getUserData") private userData: IUserData | undefined;
  @Action("getLinkSupport") private getLinkSupport: (() => void) | undefined;
  @Getter("getLinkSupport") private linkSupport: ILinkSupport[] | undefined;

  private activeCabinet: ICabinet | null = null;
  private withdrawBalance = "0";
  public reloadLoading = false;
  public retryLoading = false;

  private allCabinets: ICabinet[] = [];

  public infoSwiperModalDialog = false;

  public confirmModalDialog = false;
  public slideArrowBounce = true;
  public pagination = Pagination;
  public loading = false;
  private getCabinetsInterval: number | null = null;

  public stateWithdrawInfoDrawer = false;
  public withdrawInfoDescription: string[] = [];
  public withdrawHtmlInfoDescription = "";
  public waSupport!: string;

  public hasRequest = false;
  private hasRequestLoadingCabinets = false;

  public stateWithdrawDialog = false;
  public withdrawDialogDescription = "";

  public stateInfoWithdrawDialog = false;
  public cabinetsServiceView: "CityMobil" | "Yandex" = "Yandex";

  public isHaveCityCabinets = false;
  private isHaveYandexCabinets = false;

  private isChangingCard = false;

  public withdrawTime = 10;
  public withdrawInterval: null | number = null;

  get filterCabinetsDriverByService() {
    return this.allCabinets.filter(
      (item) => item.service === this.cabinetsServiceView,
    );
  }

  public async onRefreshPage() {
    if (!this.hasRequestLoadingCabinets) {
      await this.getCabinets(false);
      await this.fetchUserCards?.();
    }
    this.reloadLoading = false;
  }

  public generateSubtitle(item: ICard) {
    if (item.is_main) {
      if (!item.is_approved) {
        return "Карта не подтверждена";
      }

      return "Вывод на эту карту";
    }

    return "Нажмите, чтобы сделать активной";
  }

  get makeActiveCard() {
    let isActiveCard = "Вывести";
    this.payCardsData?.forEach((card) => {
      if (card.is_approved && card.is_main) {
        return (isActiveCard = "Вывести");
      } else if ((!card.is_approved && card.is_main) || card.is_main) {
        return (isActiveCard = "Подтвердить карту");
      }
    });
    return isActiveCard;
  }

  public async setActiveCard(id: string) {
    if (this.payCardsData != undefined) {
      if (this.isChangingCard) {
        if (this.$PLATFORM != "web") {
          Toast.show({ text: "Смена карты доступна не чаще раз в 10 секунд" });
        } else {
          toast("Смена карты доступна не чаще раз в 10 секунд", {
            autoClose: 3000,
            type: "warning",
          });
        }
        return;
      }

      const isAlreadyActive = this.payCardsData.find(
        (item) => item.id === id && item.is_main,
      );
      if (isAlreadyActive) {
        return;
      }
      for (const card of this.payCardsData) {
        if (card.id === id) {
          card.is_main = true;
          this.isChangingCard = true;

          await ApiCard.setMainCard(card.id);
          this.loading = false;
          await this.getCabinets(false);

          setTimeout(() => {
            this.isChangingCard = false;
          }, 10000);

          continue;
        }
        card.is_main = false;
      }
      this.payCardsData.sort((a) => {
        if (a.is_main) {
          return -1;
        }
        return 1;
      });
    }
  }

  get formattedUserFullName() {
    let userFullName = this.userData?.full_name;
    userFullName = userFullName?.split("null").join("");

    return userFullName;
  }

  public setWithdrawInfoDrawerDescription() {
    this.withdrawInfoDescription = [];
    if (this.linkSupport) {
      this.linkSupport.forEach((el) => {
        if (el?.cabinet_driver_id == this.activeCabinet?.id) {
          this.waSupport = el.wa_support;
        }
      });
    }
    const cleaned = this.waSupport?.replace(/[^\d+]/g, "");

    const formattedPhone = cleaned?.startsWith("+")
      ? cleaned.slice(1)
      : cleaned;

    const phoneNumber = encodeURI(formattedPhone);

    const userText = `Водитель ${this.formattedUserFullName} нажал на кнопку контакта с партнёром из приложения Форс Мани. Кабинет: ${this.activeCabinet?.name}. Версия приложения: ${this.$APP_TYPE}`;
    const href = encodeURI(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${userText}`,
    );
    const cabinetName = `Название кабинета: ${this.activeCabinet?.name}`;
    const minAmount = `Минимум к выводу: ${this.activeCabinet?.min_amount}`;
    const dailyLimit = `Текущий суточный лимит: ${this.activeCabinet?.limit_amount}`;
    const maxAmount = `Можно за раз: ${this.activeCabinet?.max_amount}`;
    const irreducibleBalance = `Неснижаемый остаток: ${this.activeCabinet?.irreducible_balance}`;
    const partnerWhatsapp = this.waSupport
      ? `Whatsapp партнера: <a href="${href}" target="_blank" class="text-blue underline">${phoneNumber}</a>`
      : "Партнер не указал Whatsapp. Обратитесь к партнеру. Контакты ищите в Яндекс Про";
    this.withdrawInfoDescription = [
      cabinetName,
      minAmount,
      dailyLimit,
      maxAmount,
      irreducibleBalance,
    ];

    this.withdrawHtmlInfoDescription = partnerWhatsapp;
    this.stateWithdrawInfoDrawer = true;
  }

  public validateStateWithdrawDialog() {
    if (!this.payCardsData || this.payCardsData?.length <= 0) {
      this.stateWithdrawDialog = false;
      this.$router.push("/new-card");
      return;
    }

    this.stateWithdrawDialog = false;
  }

  public openWhatsappSuttort() {
    const activeCabinet = this.activeCabinet;
    const user = store.state.userData;
    const userFullName = user?.full_name.split("null").join("");
    const waSupportNumber = this.waSupport;
    const textMessage = `Водитель ${userFullName} с номером ${user?.phone_number} нажал в приложении кнопку "У меня отключены выплаты". Кабинет ${activeCabinet?.name}`;

    const href = encodeURI(
      `https://api.whatsapp.com/send?phone=${waSupportNumber}&text=${textMessage}`,
    );
    window.open(href, "_blank");
  }

  public async validateWithdrawMoney() {
    this.getCards?.();
    if (this.linkSupport) {
      this.linkSupport.forEach((el) => {
        if (el?.cabinet_driver_id == this.activeCabinet?.id) {
          this.waSupport = el.wa_support;
        }
      });
    }
    const errorText: boolean | string = await UtilError.validateWithdrawError(
      this.activeCabinet,
      this.withdrawBalance,
      this.payCardsData as ICard[],
      this.waSupport,
    );

    if (typeof errorText == "string") {
      this.withdrawDialogDescription = errorText;
      this.stateWithdrawDialog = true;
      return;
    }
    this.withdrawTime = 10;

    this.withdrawInterval = setInterval(() => {
      this.withdrawTime -= 1;
      if (this.withdrawTime <= 1) {
        clearInterval(this.withdrawInterval as number);
        this.onConfirmWithdrawMoney();
      }
    }, 1000);

    this.confirmModalDialog = true;
  }

  public async updateActiveSlideDetails() {
    this.slideArrowBounce = false;

    const swiper = (document.getElementById("swiper") as any)?.swiper;

    if (swiper) {
      const id = swiper.slides[swiper.activeIndex].getAttribute("data-id");

      const cabinet = this.filterCabinetsDriverByService.find(
        (item) => item.id === id,
      );

      if (
        cabinet &&
        this.activeCabinet &&
        this.isCabinetEqual(this.activeCabinet, cabinet)
      )
        return;

      if (cabinet) {
        this.activeCabinet = cabinet;
        this.withdrawBalance = String(this.activeCabinet.amount);
      } else {
        this.activeCabinet = this.filterCabinetsDriverByService?.[0];
        this.withdrawBalance = String(this.activeCabinet.amount);
      }

      if (this.$PLATFORM != "web") {
        Haptics.impact({ style: ImpactStyle.Light });
      }
    }
  }

  public isCabinetEqual(a: ICabinet, b: ICabinet): boolean {
    if (!a || !b) {
      return false;
    }

    // Проверка, а не поменялись ди новые данные с API cabinets
    return (
      a.id === b.id &&
      a.max_amount === b.max_amount &&
      a.name === b.name &&
      a.balance === b.balance &&
      a.service === b.service &&
      a.irreducible_balance === b.irreducible_balance &&
      a.min_amount === b.min_amount &&
      // a.total_balance === b.total_balance &&
      a.limit_amount === b.limit_amount &&
      a.amount === b.amount &&
      a.is_payment === b.is_payment
    );
  }

  private async getCabinets(isInterval: boolean) {
    try {
      this.retryLoading = false;
      this.hasRequestLoadingCabinets = true;

      const response = await ApiHome.getCabinets();

      this.hasRequestLoadingCabinets = false;
      if (this.isValidCabinetsResponse(response)) {
        this.updateCabinetsData(response as ICabinet[], isInterval);
      } else {
        this.handleFailedCabinetLoading(response);
      }
    } catch (error) {
      this.retryLoading = true;
      throw "Error to get cabinets " + error;
    }
  }

  private updateCabinetsData(response: ICabinet[], isInterval: boolean) {
    this.isHaveCityCabinets = response.some(
      (item) => item.service === "CityMobil",
    );
    this.isHaveYandexCabinets = response.some(
      (item) => item.service === "Yandex",
    );

    if (!this.isHaveYandexCabinets) this.cabinetsServiceView = "CityMobil";

    this.allCabinets = response;

    if (!this.loading) {
      this.activeCabinet = response[0];
      this.withdrawBalance = String(response[0].amount);
    } else {
      this.updateActiveSlideDetails();
    }

    if (!isInterval) {
      const swiper = document.getElementById("swiper") as any;
      swiper?.swiper?.slideTo(0);
    }

    this.loading = true;
  }

  private isValidCabinetsResponse(response: any): boolean {
    return typeof response !== "boolean" && response?.length > 0;
  }

  private async handleFailedCabinetLoading(response: ICabinet[] | boolean) {
    this.loading = false;

    setTimeout(() => {
      if (this.$route.name === "Home") {
        this.retryLoading = true;
      }
    }, 1000);

    if (response && typeof response !== "boolean") {
      Sentry.captureException("Error with get cabinets", {
        extra: {
          cabinetsData: JSON.stringify(response),
          phone: await UtilStorage.getItem("phone"),
        },
      });
    }
  }

  public formTextWithdrawConfirm(): string {
    if (this.payCardsData != undefined) {
      if (this.payCardsData.length > 0) {
        const card = this.payCardsData[0];
        const limitAmount = this.activeCabinet?.limit_amount;
        const irreducibleBalance = this.activeCabinet?.irreducible_balance;

        return `на карту ${card.number} (${card.name})  \n Суточный лимит: ${limitAmount} \n Неснижаемый остаток: ${irreducibleBalance}`;
      }
    }

    return "";
  }

  public async onConfirmWithdrawMoney() {
    try {
      if (this.withdrawInterval) clearInterval(this.withdrawInterval);
      this.hasRequest = true;
      this.confirmModalDialog = false;

      const amount = Number(this.withdrawBalance);

      const response = await ApiHome.sendCreateTransaction(
        (this.activeCabinet as ICabinet).id,
        amount,
      );

      if (response && response?.detail === "REQUEST_ACCEPTED_PROCESSING") {
        if (this.$PLATFORM != "web") {
          Haptics.impact({ style: ImpactStyle.Light });
        }
        this.stateInfoWithdrawDialog = true;
        await this.getCabinets(false);
      } else if (
        response?.detail === "EXCEEDED_THE_PAYOUT_LIMIT" &&
        response?.time_left > 0
      ) {
        const minutes = Math.floor(response?.time_left / 60);
        const seconds = response?.time_left % 60;

        const message = `${response.message}\nСледующая выплата доступна через ${minutes} минут ${seconds} секунд`;
        UtilError.validateErrorMessageOnBackend(response?.detail, message);
      } else {
        UtilError.validateErrorMessageOnBackend(
          response?.detail,
          response.message,
        );
      }
    } finally {
      this.hasRequest = false;
    }
  }

  public clearWithdrawInterval() {
    clearInterval(this.withdrawInterval as number);
  }

  public validateCssMode() {
    return this.$PLATFORM != "web";
  }

  activated() {
    this.isChangingCard = false;
    this.getCabinets(false);
    this.fetchUserCards?.();
    this.getCabinetsInterval = setInterval(async () => {
      if (this.$route.name != "Home") {
        clearInterval(this.getCabinetsInterval as number);
        return;
      }
      if (!this.hasRequestLoadingCabinets) {
        await this.getCabinets(true);
      }
    }, 30000);

    if (!this.userData) {
      this.getUserData?.();
    }

    if (!this.linkSupport) {
      this.getLinkSupport?.();
    }
  }

  deactivated() {
    clearInterval(this.getCabinetsInterval as number);
    clearInterval(this.withdrawInterval as number);
  }
}
